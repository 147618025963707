@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;500;600;700;800;900&display=swap');

.App {
  // text-align: center;
  background-color: #171717;
}

body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #33421f66 transparent;
  /* scroll thumb and track */
  background-color: #FFFFFF;
  width: 100%;
}

.scroll-container {
  overflow-y: auto;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.scroll-container::-webkit-scrollbar {
  display: none;
}

.hide-overflow {
  width: 100% !important;
  overflow-x: hidden !important;
}

.clickable {
  cursor: pointer;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  display: flex;
}

// .ant-menu-vertical .ant-menu-item {
//   display: flex !important;
//   align-items: center;
//   img {
//     margin-bottom: 5px;
//   }
// }

// .sidebar-menu .ant-menu-vertical  .ant-menu-item {
//   display: flex !important;
//   align-items: center;
//   img {
//     margin-bottom: 5px;
//   }
// }

/////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////
/// ANTD overrided classes//////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////////////////
/// ///////////////////////////////////////////////////////////////////////
.ant-layout .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

//.ant-menu-item-selected {
//  svg {
//    fill: black;
//    //stroke: black;
//
//    path {
//      fill: black;
//      // stroke: black;
//    }
//  }
//}

.ant-segmented-group {
  border: 1px solid #333;
  border-radius: 4px;
  margin-right: -1px;
}

.ant-segmented-item {
  border-radius: 0px !important;
  border-right: 1px solid #333;
}

.ant-segmented .ant-segmented-item-label {
  margin-top: 5px;
}

//////////////////////////////////////////////////////////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////
/// ///////////////////////////ANTD Overrided classes/////////////////////////////
/// //////////////////////////////////////////////////////////////////////////////
.expand-icon {
  display: none;
  transition: opacity 0.3s;
}

.grid-img-tiles {
  cursor: pointer;
}

.grid-img-tiles:hover .expand-icon {
  display: block;
  opacity: 1;
}

.ellipse-svg-login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
  url('../fonts/Poppins.ttf') format('TrueType');
}

.home-images-grid {
  max-height: 84vh;
  @media screen and (max-height: 788px) {
    max-height: 80vh;
  }
}

.ant-select .ant-select-arrow {
  color: #4A4A4A;
}

.ant-select-focused:where(.css-dev-only-do-not-override-9s33v3).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #4A4A4A !important;
}

.Select-generate-engine .ant-select-arrow {
  color: white;
}

.Select-generate-engine .ant-select-selection-item {
  color: white !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
.ant-upload.ant-upload-select {
  width: 100%;
  height: 64px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
.ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #3CA6B9;
}

.ant-upload-wrapper {
  display: grid;
}

.collection-image {
  cursor: pointer;

  .ant-checkbox .ant-checkbox-inner {
    border-width: 3px;
    padding: 6px;
    border-radius: 6px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 3px solid #171717 !important;
    padding: 6px;
    border-radius: 6px;
  }

  .checkbox {
    position: absolute;
    top: 0.5em;
    right: 0.9em;
    display: none;
  }

  .validation-status {
    position: absolute;
    bottom: 0.5em;
    right: 0.9em;
  }

  &:hover {
    img {
      border: 2px solid #3cab6e;
    }

    .checkbox {
      display: block;
    }
  }
}

.select-image:hover {
  cursor: pointer;
  border: 2px solid #3cab6e;
}

.gradient-text {
  background: linear-gradient(90deg, #3CA6B9 0%, #4adcca 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard-hero {
  background: linear-gradient(
                  0deg,
                  rgba(12, 13, 14, 0.84) 0%,
                  rgba(12, 13, 14, 0.84) 100%
  ),
  url('../images/logoBg.jpg'),
  lightgray -570.069px -490.871px / 150.819% 414.661% no-repeat;
  background-size: cover;
}

.auth-sider {
  background: linear-gradient(90deg, #3CA6B9 0%, #4adcca 100%),
  url('../images/logoBg.jpg') no-repeat;
  background-blend-mode: color;
  background-size: cover;
}

.card-no-padding {
  .ant-card-body {
    padding: 0;
  }
}

.card-sm-padding {
  .ant-card-body {
    padding: 0.5em;
  }
}

.card-md-padding {
  .ant-card-body {
    padding: 1em;
  }
}

.ant-carousel .slick-slide img {
  border-radius: 8px;
}

.dot-class {
  border-radius: 100%;
}

.ant-divider-horizontal {
  margin: 12px 0;
}

div.ant-table-content {
  border-radius: 8px;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
}

.ant-pagination .ant-pagination-item {
  border: none;
}

.ant-pagination .ant-pagination-item:hover {
  a {
    color: #e2e2e2;
  }
}

.ant-pagination .ant-pagination-item-active {
  border: 1px solid #3CA6B9;

  a {
    color: #3CA6B9;
  }
}

.ant-pagination .ant-pagination-item-active:hover {
  border: 1px solid #3CA6B9;

  a {
    color: #3CA6B9;
  }
}

.ant-modal {
  border-radius: 12px;
}

.primary-button {
  .ant-btn-primary:hover {
    border: 1px solid #3ca6b9;
    color: #3ca6b9 !important;

    svg {
      stroke: #3ca6b9;
    }
  }
}

.primary-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #3ca6b9;
    color: #f2f2f5 !important;

    svg {
      stroke: #f2f2f5;
    }
  }
}

.secondary-button {
  .ant-btn-primary:hover {
    border: 1px solid #F2F2F5;
    color: #0D0D0D !important;

    svg {
      stroke: #0D0D0D;
    }
  }
}

.secondary-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #F2F2F5;
    color: #0D0D0D !important;

    svg {
      stroke: #0D0D0D;
    }
  }
}

.error-button {
  .ant-btn-primary:hover {
    border: 1px solid #ea332d;
    color: #ea332d !important;

    svg {
      stroke: #ea332d;
    }
  }
}

.error-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #ea332daa;
    color: #e2e2e2 !important;

    svg {
      stroke: #e2e2e2;
    }
  }
}

.progress-button {
  .ant-btn-primary:hover {
    border: 1px solid #FA8C16;
    color: #FA8C16 !important;

    svg {
      stroke: #FA8C16;
    }
  }
}

.progress-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #FA8C16aa;
    color: #e2e2e2 !important;

    svg {
      stroke: #e2e2e2;
    }
  }
}

.outlined-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #3cab6e;
    color: #3cab6e !important;

    svg {
      stroke: #3cab6e;
    }
  }
}

.secondary-outlined-button-disabled {
  .ant-btn-primary:hover {
    border: 1px solid #4a4a4a;
    color: #4a4a4a !important;

    svg {
      stroke: #4a4a4a;
    }
  }
}

.ant-checkbox .ant-checkbox-inner:after {
  border: none;
}

.hoverable-card {
  &:hover {
    border: 1px solid #B1DBE3 !important;
  }
}

.creations-card {
  .ant-card-body {
    height: 100%;
  }

  .ant-tabs .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs .ant-tabs-tabpane {
    height: 94%;
  }
}

.menu-item {
  .menu-text {
    color: #b3b3b3 !important;
  }

  svg {
    fill: #b3b3b3;
  }

  &:hover {
    .menu-text {
      color: white !important;
    }

    svg {
      fill: white;
    }
  }
}

.light-hover-tile:hover {
  background-color: #292929 !important;
}

.dark-hover-tile:hover {
  background-color: #171717 !important;
}

.ant-tag-blue {
  color: #3CA6B9;
  background-color: #EFF8F9;
  border-color: #B1DBE3;
}

.activity-item {
  border: 1px solid #3CA6B9;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;

  .text {
    color: #3CA6B9 !important;
  }
}


.editable-activity-item {
  border: 1px solid #3CA6B9;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  height: 100%;

  .text {
    color: #3CA6B9 !important;
  }

  &-selected {
    border: 1px solid #3CA6B9;
    width: 100%;
    padding: 8px 15px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    cursor: pointer;
    background-color: #3CA6B9;

    .text {
      color: white !important;
    }
  }

  &:hover {
    background-color: #EFF8F9;

    .text {
      color: #3CA6B9 !important;
    }
  }
}

.url {
  text-decoration-line: underline;

  &:hover {
    color: #3ca6b9 !important;
    cursor: pointer;
  }
}

.ant-picker-ok {
  .ant-btn-primary {
    background-color: #3ca6b9;
  }

  .ant-btn-primary:hover {
    border: 1px solid #3ca6b9;
    color: #3ca6b9 !important;

    svg {
      stroke: #3ca6b9;
    }
  }

  .ant-btn-primary:disabled {
    border: 1px solid #3ca6b9AA;
    background-color: #3ca6b9AA;
    color: white !important;

    svg {
      stroke: #3ca6b9;
    }
  }
}

//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//input:-webkit-autofill:active {
//  color: white !important;
//  transition: background-color 5000s ease-in-out 0s;
//}


